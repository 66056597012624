import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Actions } from 'reducers/appReducer';
import { OcdToast } from 'ocedong_react_components';
import { UserType, Message } from 'common/Enums';
import { useHistory } from 'react-router-dom';

interface Props {
  ChangePasswordNew?: Function;
}

const TimLaiMatKhau = (props: Props) => {
  const refNotification = useRef<any>();
  const [InputCPass, setInputCPass] = useState({
    code: '',
    passwordNew: '',
    Type: UserType.Public
  });
  const [CPass, setCPass] = useState('');
  const [item, setItem] = useState({ pass: false, cpass: false });
  const history = useHistory();

  const ValidateForm = () => {
    if (!InputCPass.passwordNew) {
      refNotification.current.show('warning', Message.Password_Is_Not_Empty);
      return false;
    }
    if (InputCPass.passwordNew.length < 6) {
      refNotification.current.show('warning', Message.Password_Wrong);
      return false;
    }
    if (CPass != InputCPass.passwordNew) {
      refNotification.current.show('warning', Message.DuplicatePassword);
      return false;
    }
    return true;
  };

  const onChangeFormCPass = (key: string, e: any) => {
    setInputCPass({
      ...InputCPass,
      [key]: e.target.value,
      ['code']: window.location.search.substring(6)
    });
  };

  const eyeChange = (key: string) => {
    if (key === 'pass') {
      setItem({
        ...item,
        [key]: !item.pass
      });
    } else {
      setItem({
        ...item,
        [key]: !item.cpass
      });
    }
  };

  const ChangePass = async () => {
    if (ValidateForm()) {
      console.log(InputCPass);
      var IsChanged = await props.ChangePasswordNew(InputCPass);
      if (IsChanged) {
        refNotification.current.show('success', `Thay đổi mật khẩu thành công`);
        history.push('/dang-nhap');
      }
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center main_dang_nhap"
      style={{ minHeight: '100vh' }}
    >
      <OcdToast ref={refNotification} />
      <section className="h-100 h-custom">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-8 col-xl-6">
              <div className="card rounded-3">
                <img
                  src={
                    process.env.ROOT_URL +
                    'StaticFiles/ImagesCache/banner-index.jpg'
                  }
                  className="w-100"
                  style={{
                    borderTopLeftRadius: '.3rem',
                    borderTopRightRadius: '.3rem'
                  }}
                  alt="Sample photo"
                />
                <div className="card-body p-4 p-md-5">
                  <h3 className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2">
                    Đổi mật khẩu
                  </h3>
                  <div>
                    <div className="form-outline input-group mb-4">
                      <input
                        type={`${item.pass ? 'text' : 'password'}`}
                        placeholder="Mật khẩu mới(*)"
                        className="form-control"
                        onChange={(e: any) => {
                          onChangeFormCPass('passwordNew', e);
                        }}
                      />
                      <span
                        className="input-group-text cursor-pointer"
                        onClick={() => {
                          eyeChange('pass');
                        }}
                      >
                        {item.pass ? (
                          <i className="bi bi-eye-fill"></i>
                        ) : (
                          <i className="bi bi-eye-slash-fill"></i>
                        )}
                      </span>
                    </div>
                    <div className="form-outline input-group mb-4">
                      <input
                        type={`${item.cpass ? 'text' : 'password'}`}
                        placeholder="Nhập lại mật khẩu(*)"
                        className="form-control"
                        onChange={(e: any) => {
                          setCPass(e.target.value);
                        }}
                      />
                      <span
                        className="input-group-text cursor-pointer"
                        onClick={() => {
                          eyeChange('cpass');
                        }}
                      >
                        {item.cpass ? (
                          <i className="bi bi-eye-fill"></i>
                        ) : (
                          <i className="bi bi-eye-slash-fill"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <p className="mb-2 text-danger dan-dotfansm">
                      Phải từ 8 ký tự trỏ lên
                    </p>
                    <p className="mb-4 text-danger dan-dotfansm">
                      Phải có ít nhất 1 chữ in hoa, 1 số và 1 ký tự đặc biệt
                    </p>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mb-4">
                    <button
                      className="header_btn bg-danger text-light"
                      style={{ width: '120px' }}
                      onClick={() => {
                        ChangePass();
                      }}
                    >
                      Lưu thay đổi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapState = ({ ...state }) => ({});
const mapDispatchToProps = {
  ChangePasswordNew: Actions.ChangePasswordNew
};

export default connect(mapState, mapDispatchToProps)(TimLaiMatKhau);
