import { Message, UserType } from 'common/Enums';
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Actions } from 'reducers/appReducer';
import { OcdToast } from 'ocedong_react_components';
import { Regular } from 'common/Regular';

interface Props {
  SendEmailRestorePassword?: Function;
}

const QuenMatKhau = (props: Props) => {
  const [InputForgetPassword, setInputForgetPassword] = useState({
    Email: '',
    Type: UserType.Public
  });

  const history = useHistory();

  const refNotification = useRef<any>();

  const ValidateFormSendEmail = () => {
    if (!InputForgetPassword.Email) {
      refNotification.current.show('warning', Message.Email_Is_Not_Empty);
      return false;
    }
    if (!Regular.email(InputForgetPassword.Email)) {
      refNotification.current.show('warning', Message.Email_Is_Not_Format);
      return false;
    }
    return true;
  };

  const GuiEmail = async () => {
    if (ValidateFormSendEmail()) {
      var IsSended = await props.SendEmailRestorePassword(
        window.location.host + '/tim-lai-mat-khau',
        InputForgetPassword.Email
      );
      if (IsSended) {
        refNotification.current.show(
          'success',
          `Gửi mail thành công! Vui lòng kiểm tra email!`
        );
      }
    }
  };

  const onChangeFormSendEmail = (key: string, e: any) => {
    setInputForgetPassword({
      ...InputForgetPassword,
      [key]: e.target.value
    });
  };

  const goToSignIn = () => {
    history.push('/dang-nhap');
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center main_dang_nhap"
      style={{ minHeight: '100vh' }}
    >
      <OcdToast ref={refNotification} />
      <section className="h-100 h-custom">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-8 col-xl-6">
              <div className="card rounded-3">
                <img
                  src={
                    process.env.ROOT_URL +
                    'StaticFiles/ImagesCache/banner-index.jpg'
                  }
                  className="w-100"
                  style={{
                    borderTopLeftRadius: '.3rem',
                    borderTopRightRadius: '.3rem'
                  }}
                  alt="Sample photo"
                />
                <div className="card-body p-4 p-md-5">
                  <h3 className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2">
                    Tìm lại mật khẩu
                  </h3>
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      placeholder="Email đăng ký"
                      className="form-control"
                      onChange={(e: any) => {
                        onChangeFormSendEmail('Email', e);
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-center align-items-center mb-4">
                    <button
                      className="header_btn bg-danger text-light "
                      style={{ width: '120px' }}
                      onClick={() => {
                        GuiEmail();
                      }}
                    >
                      Gửi
                    </button>
                  </div>
                  <p
                    style={{
                      textAlign: 'center',
                      fontSize: 'calc(0.8rem)'
                    }}
                  >
                    Bạn đã tìm được tài khoản?{' '}
                    <span
                      onClick={() => {
                        goToSignIn();
                      }}
                      className="k_dang_nhap"
                    >
                      Đăng nhập ngay nào
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapState = ({ ...state }) => ({});
const mapDispatchToProps = {
  SendEmailRestorePassword: Actions.SendEmailRestorePassword
};

export default connect(mapState, mapDispatchToProps)(QuenMatKhau);
